.tabs {
  padding: 0;
  border-bottom: 1px solid @dark-grey;
  margin: @spacing 0;

  .tab-item {
    display: inline-block;
    padding: @spacing-s @spacing;
    background: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: @dark-grey;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
    .body-s();
    .body-strong();

    &.btn {
      border: none;
      outline: none;
    }

    &.active {
      background: @dark-grey;
      color: white;
    }
  }
}
