/* Generic */
@import "variables.less";
@import "colors.less";
@import "animations.less";
// @import "fonts.less";
@import "typos.less";
// @import "helpers.less";
@import "layout.less";
@import "auth.less";
// @import "containers.less";
// @import "loader.less";
@import "buttons.less";
@import "forms.less";
@import "helpers.less";
@import "cards.less";
@import "tabs.less";
@import "lists.less";
@import "tables.less";
@import "tooltip.less";

/* Specific */
@import "notification.less";
@import "d3.less";
@import "dashboard.less";

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .fade-in();
}
body.modal-open {
  height: 100vh;
  padding-right: 15px;
  overflow-y: hidden;

  .modal-overlay {
    overflow-y: auto;
  }
}
.dialog {
  position: relative;
  width: 600px;
  max-width: 95%;
  padding: @spacing-xl @spacing-l;
  margin: 100px auto;
  background: white;
  border-radius: 5px;
  .slide-down();
  .close-btn {
    position: absolute;
    top: @spacing;
    right: @spacing;
  }

  &.large {
    width: 1000px;
    margin: 25px auto;
  }

  &.full-page {
    width: 100%;
    max-width: unset;
    height: 100%;
    margin: 0;
  }
}
.dialog-head {
  padding-bottom: @spacing;
}
.dialog-footer {
  padding-top: @spacing-l;
  text-align: center;
}

.dialog-title {
  .page-title();
}

.lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10vh 10vw;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;

  .main-zone {
    display: inline-block;
    max-width: 80vw;
    height: 100%; /* needed for child's image max-height */
    max-height: 80vh;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    iframe {
      min-width: 80vw;
      min-height: 80vh;
    }
  }

  .slide-down();
  .close-btn {
    position: absolute;
    top: @spacing;
    right: @spacing;
  }
}

.attachment {
  .card();

  padding: @spacing-s;
  border: 1px solid white;
  margin: @spacing 0;
  background: fade(white, 50%);
  .attachment-head {
    .row();
    & > * {
      .col();
    }
    .delete {
      .col-fit();
    }
  }
  .attachment-file {
    display: flex;
    align-items: center;
    .attachment-img {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 4px solid white;
      background-image: url("../img/placeholder.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 5px;
      box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
    }
    .attachment-name {
      .link();
      .link-small();

      margin-left: @spacing-xs;
    }
  }
  .attachment-desc-input {
    margin: @spacing-xs 0;
    .input {
      .body-s();

      padding: @spacing-xxs @spacing-xs;
      color: @dark-grey;
    }
  }
  .attachment-cover-choice {
    .body-xs();

    color: @grey;
  }
}

.card .attachment {
  padding: 0;
  &:not(:last-child) {
    border-bottom: 1px solid @light-grey;
  }
}

.chip {
  display: inline-flex;
  width: 2em;
  height: 2em;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 0 0.5em; // to be removed from generic component
  background: @orange; // to be removed from generic component
  border-radius: 100%;
  color: white;
  line-height: 0;
  .body-xs();
}

.chip + .chip-label {
  .body-xs();

  padding-left: @spacing-xxs;
  line-height: 2em;
}

.load-more {
  padding: @spacing 0;
  text-align: center;
}

.contribution-content-section {
  .slide-right();
}
.admin-panel {
  .slide-left();
}

// external cookie management, overload css
body #ot-sdk-btn-floating.ot-floating-button::after,
#ot-sdk-btn-floating.ot-floating-button:hover::after {
  font-size: 12.5px !important;
}
