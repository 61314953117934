@max-container-width: 1100px;

* {
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  max-width: @max-container-width;
  padding-right: @spacing-xl;
  padding-left: @spacing-xl;
  margin: 0 auto;

  @media (max-width: @bp-xs-max) {
    padding-right: @spacing-s;
    padding-left: @spacing-s;
  }
}

.v-layout {
  display: flex;
  height: 100vh;
  flex-direction: column;

  @media (max-width: @bp-s-max) {
    display: block;
  }
}

.auth-layout {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  padding: 0;
  margin: 0;

  & > * {
    display: flex;
    flex: 1 1 50%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }

  & > .sidebar {
    flex-basis: 300px;
    justify-content: center;
  }

  .btn-burger {
    position: absolute;
    top: @spacing-s;
    right: @spacing-s;
    display: none;
  }

  @media (max-width: @bp-s-max) {
    flex-direction: column;

    .btn-burger {
      display: initial;
    }

    .sidebar {
      overflow: hidden;
      flex: 0 0 100px;
      margin-bottom: @spacing-l;

      .sidebar-menu {
        display: none;
      }

      &.expanded .logo {
        height: 100px;
      }

      &:not(.expanded) .logo {
        width: auto;
        height: 40px;
      }

      &.expanded {
        @width: 88%;

        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: @width;
        height: 100%;
        box-shadow: 2px 0 5px 3px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        transition: left 0.2s linear;

        .sidebar-slide();

        .sidebar-menu {
          display: block;
        }
      }
    }
  }
}

.app-layout {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .header {
    flex: 0;
    .slide-down();

    background: url(../img/background.png) no-repeat center center;
    background-size: cover;
  }
  .banner {
    flex: 0 0 30px;
    background: url(../img/background.png) center center;
    background-size: cover;
  }
  .content {
    flex: auto;
    background: @light-grey;
  }

  .header,
  .header > .container {
    display: flex;
    height: 80px;
    align-items: center;

    .logo {
      height: 40px;
    }
    .header-nav {
      display: flex;
      align-items: center;
      margin-left: auto;

      .nav-item {
        .link();
        .link-small();

        display: flex;
        align-items: center;
        margin: @spacing-xs;
        color: white;

        .picto + span {
          margin-left: 5px;
        }

        &:not(:disabled):not(.disabled):hover {
          color: @dark-grey;
        }

        @media (max-width: @bp-s-max) {
          margin: 0;
          &:not(:first-child) {
            margin-left: 5px;
          }
          .picto + span {
            display: none;
          }
        }
      }
    }
  }
}

.page-content {
  padding-top: @spacing-xl;
  padding-bottom: @spacing-xl;
}

.page-head {
  padding-bottom: @spacing-xl;
  .fade-in();
}
.page-footer {
  .auth-footer();
}
.form-errors + .page-footer {
  padding-top: @spacing;
}

.section {
  padding-top: @spacing;
  padding-bottom: @spacing;
}
.section-s {
  padding-top: @spacing-s;
  padding-bottom: @spacing-s;
}
.section-sep:not(:last-child) {
  border-bottom: 1px solid @light-grey;
}
.section-sep-2:not(:last-child) {
  border-bottom: 1px solid lighten(@dark-grey, 55%);
}

/* grid system */
@grid-gutter: @spacing;
.grid,
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -@grid-gutter / 2;

  & > * {
    width: 100%;
    max-width: 100%;
    flex-shrink: 0;
    padding: @grid-gutter / 2;
  }

  /* Mixin for columns */
  .col-generator(@division, @col-suffix: "", @index: 1)
    when
    not
    (@index = @division) {
    .col-@{index}-@{division} when (@col-suffix = "") {
      .col-fixed();

      width: 100% / @division* @index;
    }

    .col-@{col-suffix}-@{index}-@{division} when not (@col-suffix = "") {
      @min-width: "bp-@{col-suffix}-min";

      @media (min-width: @@min-width) {
        .col-fixed();

        width: 100% / @division* @index;
      }
    }

    .col-generator(@division, @col-suffix, @index + 1);
  }

  /* auto sizing columns */
  .col,
  .col-auto {
    flex: 1 0 0%;
  }
  .col-md {
    @media (min-width: @bp-md-min) {
      .col-auto();
    }
  }

  /* fixed sizes columns */
  .col-fixed {
    flex: 0 0 auto;
  }
  .col-0 {
    .col-fixed();

    width: 0%;
    padding: 0 !important;
  }
  .col-1-1 {
    .col-fixed();

    width: 100%;
  }

  .col-generator(2);
  .col-generator(3);

  .col-generator(2, s);
  .col-generator(3, s);

  .col-md-1-1 {
    @media (min-width: @bp-md-min) {
      .col-fixed();
      .col-1-1();
    }
  }
  .col-generator(2, md);
  .col-generator(3, md);
  .col-generator(4, md);
  .col-generator(5, md);
  .col-generator(6, md);
  .col-generator(10, md);

  .col-generator(2, lg);
  .col-generator(3, lg);
  .col-generator(4, lg);
}
.row {
  flex-wrap: nowrap;

  /* fitting column */
  .col-fit {
    width: auto;
    flex: 0 0 auto;
  }
}
.row-s {
  @media (min-width: @bp-s-min) {
    .row();
  }
}
.row-md {
  @media (min-width: @bp-md-min) {
    .row();
  }
}

/* PAGES' SPECIFIC LAYOUTING */

/*    >>> contributions list page */

.admin-tab-layout {
  & > *:not(:last-child) {
    margin-bottom: @spacing-s;
  }
}

.contributions-list {
  margin-top: @spacing;
  .slide-up();
}
