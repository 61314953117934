.dashboard-layout {
  .contributions-list {
    max-height: 10 * 4em;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .contribution-item:not(:last-of-type) {
    border-bottom: 1px solid @grey;
    margin-bottom: @spacing-s;
  }

  .contribution-title {
    .heading-s();
  }

  .contribution-description {
    .body-strong();

    color: @grey;
  }

  .contribution-score {
    .heading-s();

    padding: @spacing-xs @spacing-s;
    background-color: @light-grey;
    border-radius: 2em;
  }

  .score-card {
    background-color: white;
    border-radius: 5px;
    transition: box-shadow 0.2s;

    .card-body {
      padding: @spacing;
      .heading();
    }

    .card-head {
      .heading-s();

      padding: @spacing;
      margin-bottom: @spacing-xxs;
      background-color: @blue;
      border-radius: 5px 5px 0 0;
      color: white;
    }
  }

  .dashboard-block {
    margin: @spacing @spacing-xs;

    &.row {
      justify-content: space-between;

      .col-fit {
        padding: 0;
      }
    }
  }
}
